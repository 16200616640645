@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&display=swap');

html {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
}

